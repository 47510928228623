module.exports = {
  'kurzy - boxTitle - MIU 1 až 3': 'MIU 1 až 3',
  'Rychlé hledání FIE/LPAD škol či lektorů': 'Rýchle vyhľadávanie škôl alebo lektorov',
  'Vše': 'Všetko',
  'najít': 'nájsť',
  'NAPIŠTE LOKALITU': 'NAPÍŠTE LOKALITU',
  'Nic nenalezeno. Zkuste oddálit mapu a změnit filtrování dle typu výše.': 'Nič nenájdené. Skúste oddialiť mapu a zmeniť filtrovanie podľa typu vyššie.',
  'kurzy - tab - MIU Elementární (Basic)': 'MIU Elementárna (Basic)',
  'kurzy - tab - MIU 1 až 3 (Standard)': 'MIU 1 až 3 (Standard)',
  'Vyberte si typ kurzu / druh akce': 'Vyberte si typ kurzu / druh akcie',
  'Typ kurzu:': 'Typ kurzu:',
  'kurzy - tab - Ochutnávky a semináře': 'Ochutnávky a semináre',
  'kurzy - tab - Konference a další akce': 'Konferencie a ďalšie akcie',
  'kurzy - boxTitle - Ochutnávky a semináře': 'Ochutnávky a semináre',
  'kurzy - boxTitle - Konference a další akce': 'Konferencie a ďalšie akcie',
  'kurzy - boxTitle - MIU Elementární': 'MIU Elementárna',
  'více informací': 'viac informácií',
  'Kurzy <b>řady Standard</b>  seznámí účastníky s teoretickými základy metody vycházející z Feuersteinova instrumentálního obohacování – od úvodního kurzu <b>Standard 1</b> po navazující (volitelné) <b>Standard 2</b> nebo <b>Standard 3</b>.': 'Kurzy úrovne <b>MIU 1 až 3</b> (predtým Štandard) zoznámia účastníkov s teoretickými základmi Metódy inštrumentálneho uvedomovania (MIU), so sprostredkovaním a praktickým využitím výučbových materiálov MIU (inštrumentov). Kurzy sú určené pre budúcich lektorov MIU pracujúcich s klientami vo veku od 7–8 rokov a viac. Na úvodný kurz MIU 1 nadväzujú kurzy MIU 2 a MIU 3, absolventom predchádzajúcich kurzov ponúkame kurzy MIU na zmenu. — Všetky naše kurzy sú úplne prezenčné.',
  'Kurzy řady <b>Basic</b> přinesou účastníkům variantu metody pro práci s dětmi předškolního věku nebo s jedinci se zásadnějšími problémy, vycházející z Feuersteinova instrumentálního obohacování.': 'Kurzy <b>MIU Elementárna</b> (predtým Basic) sú určené pre budúcich lektorov MIU pracujúcich s deťmi približne vo veku do 7 až 8 rokov, prípadne s klientami s vážnejšími ťažkosťami. Kurzy prehĺbia znalosti teoretických základov Metódy inštrumentálneho uvedomovania a zručností pri sprostredkovaní. Kurzy ponúkame vo variantoch MIU E8 (osemdenný) alebo MIU E5 (päťdenný); na päťdenný kurz možno nadviazať doplňujúcim kurzom MIU E3 (trojdenný). Kurz MIU kombinovaný je možným úvodom do práce s metódou. — Všetky kurzy sú úplne prezenčné.',
  'Kurz Dynamického vyšetření seznámí účastníky s teoretickými základy i s praktickými postupy a nástroji pro dynamické hodnocení učebního potenciálu.': 'Kurz Dynamického vyšetření seznámí účastníky s teoretickými základy i s praktickými postupy a nástroji pro dynamické hodnocení učebního potenciálu.',
  'Naše jednodenní semináře umožní účastníkům blíže se seznámit s metodami zážitkovou formou (ochutnávky). Nabídne tak absolventům kurzů možnost upevnit si zásady práce s metodou instrumentálního obohacování a uvědomování nebo s metodou LPAD a najít odpovědi na otázky z praxe (inspirační semináře).': 'Prvé zoznámenie s Metódou inštrumentálneho uvedomovania zážitkovou formou umožňujú jednodenné <b>ochutnávkové semináre</b>. Pre absolventov našich vzdelávacích kurzov MIU pripravujeme <b>inšpiračné semináre</b> na zdieľanie skúseností a prehĺbenie práce s MIU so zreteľom na potreby konkrétnych klientov. — Semináre a ochutnávky organizujeme v úplne prezenčnej forme.',
  '<b>Naše konference</b> pořádáme s cílem představit účastníkům různá místa a prostředí, do kterých bylo začleněno <b>MIU</b> a dynamická diagnostika učebního potenciálu jako pevná součást každodenního života.': 'ATC metod pro osobní rozvoj a teraz aj Myslenie pre život pravidelne organizujú <b>konferencie o Metóde inštrumentálneho uvedomovania</b>, ktoré sú príležitosťou pre podrobnejšie nahliadnutie na MIU z rôznych uhlov pohľadu a vytvárajú priestor pre zdieľanie skúseností z miest a prostredí, kde sa MIU v Českej republike a na Slovensku využíva. Konferencie sú príležitosťou na inšpiratívne stretnutie pre všetkých, ktorí sa venujú MIU alebo majú záujem ju spoznať.',
  'Lektoři a školy': 'Spolupracujeme',
  'Spolupracujeme - sedy text pod hlavickou': 'Naše Akreditované tréningové centrum školí lektorov v širokom spektre organizácií a inštitúcií v celej <strong>Českej a Slovenskej republike</strong>. Na Slovensku podporuje šírenie Metódy instrumentálneho uvedomovania naša pobočka Myslenie pre život. S MIU sme zamierili aj do <strong>Holandska</strong>. Na mape nájdete školy a lektorov, ktorí aktívne pracujú s MIU a želali si byť tu uvedení. <br /><br /> Okrem škôl a ďalších vzdelávacích organizácií šírime znalosti metódy a práce s ňou v poradenských pracoviskách, detských domovoch a výchovných ústavoch, organizáciách pre ľudí s hendikepmi, diagnostických ústavoch, psychiatrických a psychoterapeutických zariadeniach, väzenských zariadeniach, komunitných centrách v sociálne vylúčených lokalitách alebo domovoch pre seniorov.',
  'přejít na web': 'ísť na web',
  'načíst další': 'načítať ďalšie',}